import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import env from "../config";
// import { ENV } from "../redux/actions";
import FooterLinkSection from "./FooterLinkSection";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "./SvgIcons";

function Footer() {
  const FooterData: any = useSelector(
    (state: any) => state?.Footer?.FooterContent
  );

  

 

  // console.log("FOOTER", FooterData);

  return (
    <div className="bg-black flex flex-col  justify-center pb-3 md:pt-20 ">
      <div className="flex-col flex w-full xl:w-[85%] md:flex md:flex-row md:p-3 xl:justify-between md:border-b-0.5 xl:h-[220px] md:border-gray-300 xl:mx-auto  ">
        <div className=" p-1 " data-aos="fade-up">
          <div >
            <Link to="/" className="hidden md:flex">
              <img
                src={`${env}${FooterData?.FooterLogo?.data?.attributes?.url}`}
                alt="logo"
                className="mx-auto"
              />
            </Link>
            {/* <div className="mt-4 text-white font-body w-80  text-1">
              {FooterData?.DescriptionText}
            </div> */}
            <div className=" footericons px-6 md:px-1  md:w-[200px] w-4/5 hidden sm:flex flex-row p-1   mt-5 justify-between mx-auto ">
              <a
                href={FooterData?.FacebookURL}
                target={"_blank"}
                // className="footericons"
              >
                <FacebookIcon />
              </a>
              <a href={FooterData?.InstagramURL} target={"_blank"}>
                <InstagramIcon />
              </a>
              {/* <a href="https://twitter.com/Hoolva_meet" target={"_blank"}>
                <TwitterIcon />
              </a> */}
              <a href={FooterData?.LinkedInURL} target={"_blank"}>
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
        <div className=" px-2 sm:p-2 py-3 sm:py-0 flex flex-col sm:flex-row w-auto justify-evenly flex-1 " data-aos="fade-up">

        
          <div className=" w-auto px-2 sm:p-2 py-1 ">
            <FooterLinkSection
              title={"Company"}
              data={[
                { text: "About Hoolva", path: "/about" ,type:"inside-link"},
                { text: "Careers at Hoolva", path: "/careers",type:"inside-link" },
                { text: "Contact Us", path: "/contact-us",type:"inside-link" },
                // { text: "Privacy Policy", path: "/privacy-policy" },
              ]}
            />
          </div>
       

        
          <div className=" w-auto px-2 sm:p-2  py-1  ">
            <FooterLinkSection
              title={"Developers"}
              data={[
                { text: "Hoolva SDK", path: "/hoolva-sdk",type:"inside-link" },
                { text: "Documentation", path: "https://docs.hoolva.com",type:"outside-link" },
                // { text: "Mobile SDK", path: "/mobile-sdk" },
                // { text: "API Reference", path: "/api-reference" },
                // { text: "Help Center", path: "/help-center" },
              ]}
            />
          </div>
      

        
          <div className=" w-auto px-2 sm:p-2 py-1  ">
            <FooterLinkSection
              title={"Hoolva Partnership"}
              data={[
                {
                  text: "Partner Advantages",
                  path: "/partnership",
                  type:"inside-link"
                },
                {
                  text: "Partnership Verticals",
                  path: "/partnership",type:"inside-link"
                },
                { text: "Become a Partner", path: "/partnership",type:"inside-link" },
                // { text: "Terms and Conditions", path: "/terms-and-conditions" },
              ]}
            />
          </div>
     

        
          <div className=" w-auto px-2 sm:p-2  py-1">
            <FooterLinkSection
              title={"Resources"}
              data={[
                // { text: "Case Studies", path: "/case-studies" },
                // { text: "Use Cases", path: "/use-cases" },
                { text: "Documentation", path: "https://docs.hoolva.com",type:"outside-link" },
                { text: "System Status", path: "/system-status",type:"inside-link" },
                { text: "Support", path: "/support",type:"inside-link"},
              ]}
            />
          </div>
          <div className=" footericons px-6 md:px-1 md:w-[200px] w-3/4  flex flex-row p-1 sm:hidden   mt-5 justify-between mx-auto ">
              <a
                href={FooterData?.FacebookURL}
                target={"_blank"}
                // className="footericons"
              >
                <FacebookIcon />
              </a>
              <a href={FooterData?.InstagramURL} target={"_blank"}>
                <InstagramIcon />
              </a>
              {/* <a href="https://twitter.com/Hoolva_meet" target={"_blank"}>
                <TwitterIcon />
              </a> */}
              <a href={FooterData?.LinkedInURL} target={"_blank"}>
                <LinkedinIcon />
              </a>
            </div>
      
        </div>

      </div>
      <div className="w-11/12  text-white text-center mx-auto py-6 text-[12px] md:text-[14px] lg:text-[16px]  xl:text-[18px]  h-[20px]">
        {FooterData?.CopyrightVersionText}
      </div>
    </div>
  );
}

export default Footer;
